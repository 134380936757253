
.editor-nav {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 56px;
}

.editor-nav * {
  z-index: 99;
}

.edit-post-header{
  top: 0;
}

@media (min-width: 782px) {
  .edit-post-layout__content {
    margin-left: 0 !important;
  }
}

